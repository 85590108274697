import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import React, { Fragment } from "react";

import "./style.css";
import Quicklinks from "./Quicklinks";
function Academia_Jobs() {
  return (
    <Fragment>
      <Header />
      <div class="container pt-6">
        <div class="row justify-content-center">
          <div class="col-8 col-sm-12 col-md-8 col-lg-8">
            <div class="p-1">
              <div class="academia-jobs">
                <div class="">
                  <h2>Jobs Posted</h2>
                  <Link
                    to="/Academia_Create_Job"
                    class="job-add p-1 text-no-decoration"
                  >
                    <i class="fa fa-solid fa-plus"></i>
                    Create Job
                  </Link>
                </div>
                <div class="job-card pt-4">
                  <h3>Research Assistant</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-view p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-eye"></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-update p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-plus"></i>
                          Update
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-delete p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-trash"></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h3>Research Assistant</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-view p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-eye"></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-update p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-plus"></i>
                          Update
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-delete p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-trash"></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h3>Research Assistant</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-view p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-eye"></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-update p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-plus"></i>
                          Update
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-delete p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-trash"></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h3>Research Assistant</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-view p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-eye"></i>
                          View
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-update p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-plus"></i>
                          Update
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-delete p-1 text-no-decoration"
                        >
                          <i class="fa fa-solid fa-trash"></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
              </div>
            </div>
          </div>
          <Quicklinks />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Academia_Jobs;
