import React, { Fragment } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

function DeiInitiatives() {
  return (
    <Fragment>
      <Header />
      <div>
        <div className="container pt-6">
          <div className="row justify-content-center">
            <div className="col-8 col-sm-12 col-md-12 col-lg-12">
              <div className="p-1">
                <div className="academia-jobs">
                  <div>
                    <h2>DEI Initiatives</h2>
                  </div>

                  <div className="job-card pt-4">
                    <div>
                      <h3>Inclusive Hiring Practices</h3>
                      <p>
                        Our academic institution is committed to fair and
                        inclusive hiring practices. We actively seek to
                        diversify our faculty and staff by employing
                        non-discriminatory recruitment processes. Our goal is to
                        increase representation from underrepresented minority
                        (URM) groups in our academic community.
                      </p>
                    </div>
                    <hr className="text-color-1" />
                  </div>

                  <div className="job-card pt-4">
                    <div>
                      <h3>Diverse Curriculum</h3>
                      <p>
                        We believe in the importance of a diverse and inclusive
                        curriculum. Our course materials, teaching methods, and
                        academic programs incorporate diverse perspectives,
                        voices, and experiences. We strive to provide an
                        educational experience that reflects the rich diversity
                        of our student body and fosters a more inclusive
                        learning environment.
                      </p>
                    </div>
                    <hr className="text-color-1" />
                  </div>

                  <div className="job-card pt-4">
                    <div>
                      <h3>Inclusive Campus Environment</h3>
                      <p>
                        Our institution actively works to create an inclusive
                        and welcoming campus environment for all students,
                        faculty, and staff. We organize programs, events, and
                        resources that promote diversity, equity, and inclusion
                        on campus. These include diversity workshops, cultural
                        celebrations, safe space programs, and support services
                        for marginalized communities.
                      </p>
                    </div>
                    <hr className="text-color-1" />
                  </div>

                  <div className="job-card pt-4">
                    <div>
                      <h3>Supporting Underrepresented Groups</h3>
                      <p>
                        We are dedicated to supporting underrepresented minority
                        (URM) students, faculty, and staff. We offer mentorship
                        programs, scholarships, networking opportunities, and
                        affinity groups to foster a sense of belonging and
                        empowerment for URM individuals.
                      </p>
                    </div>
                    <hr className="text-color-1" />
                  </div>

                  <div className="job-card pt-4">
                    <div>
                      <h3>DEI Initiatives and Events</h3>
                      <p>
                        Our academic institution organizes various DEI-focused
                        initiatives and events. We hold workshops, conferences,
                        and seminars dedicated to exploring and addressing
                        diversity-related topics.
                      </p>
                    </div>
                    <hr className="text-color-1" />
                  </div>

                  <div className="job-card pt-4">
                    <div>
                      <h3>Commitment to Equity</h3>
                      <p>
                        We are committed to equity in all aspects of our
                        operations. Our goal is to create an equitable and fair
                        environment that promotes success and opportunities for
                        all members of our academic community.
                      </p>
                    </div>
                    <hr className="text-color-1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="p-1">
                <div className="academia-jobs">
                  <h2>Quicklinks</h2>
                  <ul className="">
                    <li>
                      <Link to="/Dashboard" className="href">
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link to="/DeiEvents" className="href">
                        Event List
                      </Link>
                    </li>
                    <li>
                      <Link to="/DeiCreateevents" className="href">
                        Create Event
                      </Link>
                    </li>
                    <li>
                      <Link to="/DeiApplications" className="href">
                        View Applications
                      </Link>
                    </li>
                    <li>
                      <Link to="/academia_chats" className="href">
                        Chats
                      </Link>
                    </li>
                    <li>
                      <Link to="/academia_chats" className="href">
                        Settings
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default DeiInitiatives;
