import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PageName from "./PageName";
import "./admin.css";
import "./admin2.css";

function Update() {
  return (
    <Fragment>
      <Header />
      <h2>
        <PageName pageName="Update Entry" />
      </h2>

      <div class="container-update">
        <div class="card-update">
          <div class="card-form-update">
            <form>
              <div class="form-menu-update">
                <label for="name">Name:</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div class="form-menu-update">
                <label for="email">Email:</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div class="form-menu-update">
                <label for="name">Contact Number:</label>
                <input type="number" id="contact" name="number" required />
              </div>
              <div class="form-menu-update">
                <label for="name">Date of Birth:</label>
                <input type="date" id="date" name="date" required />
              </div>
              <div class="form-menu-update">
                <label for="message">Address:</label>
                <textarea
                  id="message"
                  name="message"
                  rows="3"
                  required
                ></textarea>
              </div>
              <div class="form-menu-update">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
}
export default Update;
