import { Link } from "react-router-dom";
import React, { Fragment } from "react";

import "./style.css";

function Quicklinks() {
  return (
    <div class="col-3 col-sm-12 col-md-4 col-lg-4">
      <div class="p-1">
        <div class="academia-jobs">
          <h2>Quicklinks</h2>
          <ul class="">
            <li>
              <Link to="/academia_dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/academia_jobs">Job Listings</Link>
            </li>
            <li>
              <Link to="/candidate_profiles">Candidate Profiles</Link>
            </li>
            <li>
              <Link to="/chats">Chats</Link>
            </li>
            <li>
              <Link to="/academia_application">View Applications</Link>
            </li>
            <li>
              <Link to="/academia_create_job">Create Job</Link>
            </li>
            <li>
              <Link to="/academia_about">About</Link>
            </li>
            <li>
              <Link to="/home">Sign Out</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Quicklinks;
