import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import React, { Fragment } from "react";

import "./style.css";

function Services() {
  return (
    <Fragment>
      <Header />
      <div class="container pt-6 text-center">
        <h1 class="text-color-1">Our Services</h1>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-3 col-sm-12 col-md-6 col-lg-3">
            <div class="p-1">
              <div class="card">
                <h2 class="text-center">Simple Setting</h2>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Ducimus perferendis consequatur suscipit commodi beatae porro
                  perspiciatis illum dolorum facilis! Numquam aut earum esse
                  placeat cum minus cumque sit non dolorem!
                </p>
              </div>
            </div>
          </div>
          <div class="col-3 col-sm-12 col-md-6 col-lg-3">
            <div class="p-1">
              <div class="card">
                <h2 class="text-center">Online Service</h2>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Ducimus perferendis consequatur suscipit commodi beatae porro
                  perspiciatis illum dolorum facilis! Numquam aut earum esse
                  placeat cum minus cumque sit non dolorem!
                </p>
              </div>
            </div>
          </div>
          <div class="col-3 col-sm-12 col-md-6 col-lg-3">
            <div class="p-1">
              <div class="card">
                <h2 class="text-center">Easy Hiring</h2>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Ducimus perferendis consequatur suscipit commodi beatae porro
                  perspiciatis illum dolorum facilis! Numquam aut earum esse
                  placeat cum minus cumque sit non dolorem!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Services;
