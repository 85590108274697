import Admin from "./components/Admin/Admin";


import Update from "./components/Admin/Update";
import Chat from "./components/Admin/Chat"
import Troubleshoot from "./components/Admin/Troubleshoot";
import ApproveNewUser from "./components/Admin/ApproveNewUser";
import CreateUserEntry from "./components/Admin/CreateUserEntry";
import DeleteUserEntry from "./components/Admin/DeleteUserEntry";
import UpdateUserEntry from "./components/Admin/UpdateUserEntry";
import ViewRegistrations from "./components/Admin/ViewRegistrations";
import MonitorUserActivity from "./components/Admin/MonitorUserActivity";

import DeiApplications from "./components/DEI/DeiApplications";
import Dashboard from "./components/DEI/Dashboard";
import DeiCreateevents from "./components/DEI/DeiCreateevents";
import DeiEvents from "./components/DEI/DeiEvents";
import DeiGoals from "./components/DEI/DeiGoals";
import DeiInitiatives from "./components/DEI/DeiInitiatives";

import Sign from "./components/Academia/Sign";
import About from "./components/Academia/About";
import Academia_About from "./components/Academia/Academia_About";
import Academia_Application from "./components/Academia/Academia_Application";
import Academia_Candidate_Profile from "./components/Academia/Academia_Candidate_Profile";
import Academia_Candidates from "./components/Academia/Academia_Candidates";
import Academia_Create_Job from "./components/Academia/Academia_Create_Job";
import Academia_Dashboard from "./components/Academia/Academia_Dashboard";
import Contact_Us from "./components/Academia/Contact_Us";
import Forgot_Password from "./components/Academia/Forgot_Password";
import Home from "./components/Academia/Home";
import Services from "./components/Academia/Services";
import Academia_Jobs from "./components/Academia/Academia_Jobs";

import AcademiaAboutCandidate from "./components/URMCandidate/AcademiaAboutCandidate";
import AppliedJobs from "./components/URMCandidate/AppliedJobs";
import ApplyJobs from "./components/URMCandidate/ApplyJobs";
import DashBoardURM from "./components/URMCandidate/DashBoardURM";
import ManageProfile from "./components/URMCandidate/ManageProfile";
import Preferences from "./components/URMCandidate/Preferences";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
           <Route path="/admin" element={<Admin />} />
          <Route path="/Chat" element={<Chat />} />
          <Route path="/createUserEntry" element={<CreateUserEntry />} />
          <Route path="/viewRegistrations" element={<ViewRegistrations />} />
          <Route path="/updateUserEntry" element={<UpdateUserEntry />} />
          <Route path="/deleteUserEntry" element={<DeleteUserEntry />} />
          <Route path="/approveNewUser" element={<ApproveNewUser />} />
          <Route
            path="/monitorUserActivity"
            element={<MonitorUserActivity />}
          />
          <Route path="/update" element={<Update />} />
          <Route path="/troubleshoot" element={<Troubleshoot />} />
           <Route
            path="/URMCandidateDashboard"
            element={<DashBoardURM />}
          />
          <Route
            path="/academicInstitutionDashboard"
            element={<Academia_Dashboard />}
          />
          <Route path="/dashboard" element={<Dashboard />} />

          {/* DEI Dashboard */}
          
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/DeiApplications" element={<DeiApplications />} />
          <Route path="/DeiCreateevents" element={<DeiCreateevents />} />
          <Route path="/DeiEvents" element={<DeiEvents />} />
          <Route path="/DeiGoals" element={<DeiGoals />} />
          <Route path="/DeiInitiatives" element={<DeiInitiatives />} />

          {/* Academia */}
          <Route path="/about" element={<About />} />
          <Route path="/academia_about" element={<Academia_About />} />
          <Route
            path="/academia_application"
            element={<Academia_Application />}
          />
          <Route
            path="/academia_candidate_profile"
            element={<Academia_Candidate_Profile />}
          />
          <Route path="/academia_candidate" element={<Academia_Candidates />} />
          <Route
            path="/academia_create_job"
            element={<Academia_Create_Job />}
          />
          <Route path="/" element={<Home />} />
          <Route path="/academia_dashboard" element={<Academia_Dashboard />} />
          <Route path="/academia_jobs" element={<Academia_Jobs />} />
          <Route path="/contact_us" element={<Contact_Us />} />
          <Route path="/forgotPassword" element={<Forgot_Password />} />
          <Route path="/home" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/sign" element={<Sign />} />

          {/* URM Candidate */}
          <Route
            path="/academiaAboutCandidate"
            element={<AcademiaAboutCandidate />}
          />
          <Route
            path="/academiaAboutCandidate"
            element={<AcademiaAboutCandidate />}
          />
          <Route path="/applyJobs" element={<ApplyJobs />} />
          <Route path="/appliedJobs" element={<AppliedJobs />} />
          <Route path="/dashboardURM" element={<DashBoardURM />} />
          <Route path="/manageProfile" element={<ManageProfile />} />
          <Route path="/preferences" element={<Preferences />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
