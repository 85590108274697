import Header from "./Header";
import Footer from "./Footer";
import React, { Fragment } from "react";

import "./stylecandidate.css";

function AcademiaAboutCandidate() {
  return (
    <Fragment>
      <Header />
      <div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 sol-md-8 col-lg-8 col-8">
              <div className="academia-about">
                <h1>Institution Name</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <h3>Research Areas</h3>
                <ol className="about-list">
                  <li>Psychology</li>
                  <li>Mathematics</li>
                  <li>Physiology</li>
                </ol>
              </div>
            </div>
            <div className="col-md-2 col-lg-2 col-2"></div>
          </div>
        </div>
        <div className="container justify-content-center">
          <table className="w-100 table-faculty">
            <thead className="thead-faculty">
              <th>Professor</th>
              <th>Postion</th>
              <th>Email</th>
              <th>Contact</th>
            </thead>
            <tbody>
              <tr>
                <td>John Doe</td>
                <td>Phd Assistant</td>
                <td>jvd1902@mavs.uta.edu</td>
                <td>+1 684-274-5982</td>
              </tr>
              <tr>
                <td>John Doe</td>
                <td>Phd Assistant</td>
                <td>jvd1902@mavs.uta.edu</td>
                <td>+1 684-274-5982</td>
              </tr>
              <tr>
                <td>John Doe</td>
                <td>Phd Assistant</td>
                <td>jvd1902@mavs.uta.edu</td>
                <td>+1 684-274-5982</td>
              </tr>
              <tr>
                <td>John Doe</td>
                <td>Phd Assistant</td>
                <td>jvd1902@mavs.uta.edu</td>
                <td>+1 684-274-5982</td>
              </tr>
              <tr>
                <td>John Doe</td>
                <td>Phd Assistant</td>
                <td>jvd1902@mavs.uta.edu</td>
                <td>+1 684-274-5982</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default AcademiaAboutCandidate;
