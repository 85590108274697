import React, { Fragment } from "react";
import "./style.css"; // Make sure to import the CSS file if it exists.
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

function DeiApplications() {
  return (
    <Fragment>
      <Header />
      <div>
        <div className="container pt-6">
          <div className="row justify-content-center">
            <div className="col-8 col-sm-12 col-md-12 col-lg-12">
              <div className="p-1">
                <div className="academia-jobs">
                  <div>
                    <h2>Candidates</h2>
                    <input
                      type="text"
                      placeholder="Search"
                      className="display-inline ip"
                      name="searchbar"
                    />
                    <p className="text-right">
                      <select id="" className="ip" name="search_filters">
                        <option value="name">Name</option>
                        <option value="location">Location</option>
                        <option value="education">Education</option>
                        <option value="research">Research</option>
                      </select>
                    </p>
                    <button className="ip-button">Search</button>
                  </div>

                  <div className="job-card pt-4">
                    <h2>Candidate Name</h2>
                    <p>Location</p>
                    <p>Education</p>
                    <div className="text-right">
                      <ul className="academic-job-list">
                        <li>
                          <Link
                            to="/"
                            className="href job-view p-1 text-no-decoration"
                          >
                            <i className="fa fa-check"></i>
                            Accept
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/"
                            className="href job-delete p-1 text-no-decoration"
                          >
                            <i className="fa fa-solid fa-trash"></i>
                            Decline
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/"
                            className="href job-update p-1 text-no-decoration"
                          >
                            <i className="fa fa-solid fa-message"></i>
                            Chat
                          </Link>
                        </li>
                      </ul>
                      <br />
                    </div>
                    <hr className="text-color-1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="p-1">
                <div className="academia-jobs">
                  <h2>Quicklinks</h2>
                  <ul className="">
                    <li>
                      <Link to="/Dashboard" className="href">
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link to="/DeiEvents" className="href">
                        Event List
                      </Link>
                    </li>
                    <li>
                      <Link to="/DeiCreateevents" className="href">
                        Create Event
                      </Link>
                    </li>
                    <li>
                      <Link to="/DeiApplications" className="href">
                        View Applications
                      </Link>
                    </li>
                    <li>
                      <Link to="/academia_chats" className="href">
                        Chats
                      </Link>
                    </li>
                    <li>
                      <Link to="/academia_chats" className="href">
                        Settings
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default DeiApplications;
