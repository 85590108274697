import React, { Fragment } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import PageName from "./PageName";
import "./admin.css";
import "./admin2.css";
import Footer from "./Footer";
function ApproveNewUser() {
  return (
    <Fragment>
      <Header />
      <h2>
        <PageName pageName="Approve New User" />
      </h2>

      <div class="SearchRecords">
        <h4>Search by name to Approve the record: </h4>
        <input type="text" class="search-input" placeholder="Search..." />
      </div>
      <div class="search-update">
        <button type="submit">Search</button>
      </div>

      <div class="container-approve">
        <table>
          <tr class="heading-table">
            <th>Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Date of Birth(YYYY-MM-DD)</th>
            <th>Location</th>
            <th>Experience(years)</th>
            <th>Approve</th>
            <th>Reject</th>
          </tr>
          <tr class="data-table">
            <th>John Jacob</th>
            <th>John.Jacob@gmail.com</th>
            <th>9433723140</th>
            <th>1991-05-03</th>
            <th>Plano, Texas</th>
            <th>9</th>
            <th>
              <Link to="/">
                <i class="checkIcon fa fa-check" />
              </Link>
            </th>
            <th>
              <Link to="/">
                <i class="checkIcon fa fa-close" />
              </Link>
            </th>
          </tr>
          <tr class="data-table">
            <th>Daisy Shah</th>
            <th>DaisyS@gmail.com</th>
            <th>9126723147</th>
            <th>1999-06-12</th>
            <th>Arlington, Texas</th>
            <th>1</th>
            <th>
              <Link to="/">
                <i class="checkIcon fa fa-check" />
              </Link>
            </th>
            <th>
              <Link to="/">
                <i class="checkIcon fa fa-close" />
              </Link>
            </th>
          </tr>
          <tr class="data-table">
            <th>Ron Harley</th>
            <th>Ron.Harley@gmail.com</th>
            <th>9456453120</th>
            <th>1989-08-15</th>
            <th>Orlando, Florida</th>
            <th>10</th>
            <th>
              <Link to="/">
                <i class="checkIcon fa fa-check" />
              </Link>
            </th>
            <th>
              <Link to="/">
                <i class="checkIcon fa fa-close" />
              </Link>
            </th>
          </tr>
          <tr class="data-table">
            <th>Chris Equale</th>
            <th>Chris.Equale@gmail.com</th>
            <th>9452673140</th>
            <th>1994-12-03</th>
            <th>Phoenix, Arizon</th>
            <th>3</th>
            <th>
              <Link to="/">
                <i class="checkIcon fa fa-check" />
              </Link>
            </th>
            <th>
              <Link to="/">
                <i class="checkIcon fa fa-close" />
              </Link>
            </th>
          </tr>
          <tr class="data-table">
            <th>Katy Perry</th>
            <th>Katy.P@gmail.com</th>
            <th>9351072314</th>
            <th>1995-10-11</th>
            <th>Seattle, Washington</th>
            <th>6</th>
            <th>
              <Link to="/">
                <i class="checkIcon fa fa-check" />
              </Link>
            </th>
            <th>
              <Link to="/">
                <i class="checkIcon fa fa-close" />
              </Link>
            </th>
          </tr>
          <tr class="data-table">
            <th>Olivia Watson</th>
            <th>Olli.Watson@gmail.com</th>
            <th>9476723570</th>
            <th>1999-07-23</th>
            <th>Irvine, California</th>
            <th>2</th>
            <th>
              <Link to="/">
                <i class="checkIcon fa fa-check" />
              </Link>
            </th>
            <th>
              <Link to="/">
                <i class="checkIcon fa fa-close" />
              </Link>
            </th>
          </tr>
        </table>
      </div>
      <div class="pt-6"></div>
      <div class="pt-6"></div>
      <Footer />
    </Fragment>
  );
}
export default ApproveNewUser;
