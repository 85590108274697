import Quicklinks from "./Quicklinks";
import Header from "./Header";
import Footer from "./Footer";
import React, { Fragment } from "react";

import "./style.css";

function Academia_Candidate_Profile() {
  return (
    <Fragment>
      <Header />
      <div class="container pt-6">
        <div class="row justify-content-center">
          <div class="col-6 col-sm-12 col-md-8 col-lg-8">
            <div class="p-1">
              <div class="academia-candidate-profile">
                <h2>Edit Candidate Profile</h2>
                <form action="/">
                  <label for="name">Candidate Name</label>
                  <input
                    type="text"
                    class="ip"
                    name="name"
                    placeholder="Enter Candidate Name"
                    value="John Doe"
                  />
                  <br />
                  <br />
                  <label for="education">Education Level</label>
                  <input
                    type="text"
                    class="ip"
                    name="education"
                    placeholder="Enter Candidate Education"
                    value="Masters in Computer Science"
                  />
                  <br />
                  <br />
                  <label for="education">Research Experience</label>
                  <input
                    type="text"
                    class="ip"
                    name="education"
                    placeholder="Enter Candidate Education"
                    value="Doctoral Researcher"
                  />
                  <br />
                  <br />
                  <label for="resume">Resume</label>
                  <input type="file" class="ip" name="resume" id="" />
                  <input
                    type="text"
                    class="ip"
                    placeholder="Enter Research Experience"
                  />
                  <br />
                  <br />
                  <label for="job_location">Location Preference</label>
                  <select id="" class="ip" name="job_location">
                    <option value="Arlington">Arlington</option>
                    <option value="Dallas">Dallas</option>
                    <option value="Austin">Austin</option>
                  </select>{" "}
                  <br />
                  <br />
                  <br />
                  <label for="job_type">Job Type Preferenece</label>
                  <select id="" class="ip" name="job_type">
                    <option value="PhD">PhD</option>
                    <option value="postdoc">postdoc</option>
                    <option value="faculty">faculty</option>
                  </select>{" "}
                  <br />
                  <br />
                  <br />
                  <input type="submit" class="ip-button" value="Save Changes" />
                </form>
              </div>
            </div>
          </div>
          <Quicklinks />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Academia_Candidate_Profile;
