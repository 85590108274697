import React, { Fragment, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PageName from "./PageName";
import "./admin.css";
import "./admin2.css";

function DeleteUserEntry() {
  return (
    <Fragment>
      <Header />
      <h2>
        <PageName pageName="Delete User Entry" />
      </h2>

      <div class="SearchRecords">
        <h4>Search by name to Delete the record: </h4>
        <input type="text" class="search-input" placeholder="Search..." />
      </div>
      <div class="search-update">
        <button type="submit">Search</button>
      </div>

      <div class="container-delete">
        <table>
          <tr class="heading-table">
            <th>Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Date of Birth(YYYY-MM-DD)</th>
            <th>Location</th>
            <th>Experience(years)</th>
            <th>Delete</th>
          </tr>
          <tr class="data-table">
            <th>Royce Dsouza</th>
            <th>Royce.Dsouza@gmail.com</th>
            <th>9456723140</th>
            <th>1996-03-21</th>
            <th>Austin, Texas</th>
            <th>3</th>
            <th>
              <a href="">
                <i class="fa fa-trash-o"></i>
              </a>
            </th>
          </tr>
          <tr class="data-table">
            <th>James</th>
            <th>Jame198@gmail.com</th>
            <th>9456723147</th>
            <th>1993-01-02</th>
            <th>Irving, Texas</th>
            <th>7</th>
            <th>
              <a href="Update.html">
                <i class="fa fa-trash-o"></i>
              </a>
            </th>
          </tr>
          <tr class="data-table">
            <th>Cathelyn Brown</th>
            <th>Cathelyn.Brown@gmail.com</th>
            <th>9456723120</th>
            <th>1998-06-05</th>
            <th>Knoxville, Tennesse</th>
            <th>5</th>
            <th>
              <a href="">
                <i class="fa fa-trash-o"></i>
              </a>
            </th>
          </tr>
          <tr class="data-table">
            <th>Benjamin Fernandes</th>
            <th>Benjamin.Fernandes@gmail.com</th>
            <th>9452723140</th>
            <th>2000-11-30</th>
            <th>Phoenix, Arizon</th>
            <th>3</th>
            <th>
              <a href="">
                <i class="fa fa-trash-o"></i>
              </a>
            </th>
          </tr>
          <tr class="data-table">
            <th>Sissy Mcguire</th>
            <th>Sissy.M@gmail.com</th>
            <th>9356723140</th>
            <th>1995-10-15</th>
            <th>Seattle, Washington</th>
            <th>7</th>
            <th>
              <a href="">
                <i class="fa fa-trash-o"></i>
              </a>
            </th>
          </tr>
          <tr class="data-table">
            <th>Andre Watson</th>
            <th>Andre.Watson@gmail.com</th>
            <th>9476723140</th>
            <th>1992-04-18</th>
            <th>Irvine, California</th>
            <th>5</th>
            <th>
              <a href="">
                <i class="fa fa-trash-o"></i>
              </a>
            </th>
          </tr>
        </table>
      </div>
      <div class="pt-6"></div>
      <div class="pt-6"></div>
      <Footer />
    </Fragment>
  );
}
export default DeleteUserEntry;
