import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import PageName from "./PageName";
import "./admin.css";
import "./admin2.css";
import Header from "./Header";
import Footer from "./Footer";
import img_url from "./img/PieChart.png"
function Admin() {
  return (
    <Fragment>
      <Header />
      <h2>
        <PageName pageName="Admin Page" />
      </h2>
      <div class="containerAdmin">
        <div class="leftAdmin">
          <div class="leftLeftAdmin">
            {/* 1 */}
            <div class="leftAdminCard">
              <Link to="/viewRegistrations" class="cardMenuAdmin">
                <h3>View User Registrations</h3>
              </Link>
            </div>
            {/* 2 */}
            <div class="leftAdminCard">
              <Link to="/approveNewUser" class="cardMenuAdmin">
                <h3>Approve New User</h3>
              </Link>
            </div>
          </div>

          <div class="leftLeftAdmin">
            {/* 3 */}
            <div class="leftAdminCard">
              <div class="dropdown-admin">
                <a href="#" class="cardMenuAdmin">
                  <h3>Manage User Profiles</h3>
                </a>
                <div class="dropdown-admin-content">
                  <Link to="/createUserEntry" class="cardMenuAdmin">
                    <h3>Create User Entry</h3>
                  </Link>
                  <Link to="/viewRegistrations" class="cardMenuAdmin">
                    <h3>Read User Entry</h3>
                  </Link>
                  <Link to="/updateUserEntry" class="cardMenuAdmin">
                    <h3>Update User Entry</h3>
                  </Link>
                  <Link to="/deleteUserEntry" class="cardMenuAdmin">
                    <h3>Delete User Entry</h3>
                  </Link>
                </div>
              </div>
            </div>
            {/* 4 */}
            {/* uncomment */}
            <div class="leftAdminCard">
              <Link to="/dashboard" class="cardMenuAdmin">
                <h3>DEI Dashboard</h3>
              </Link>
            </div>
          </div>

          <div class="leftLeftAdmin">
            {/* 5 */}
            {/* uncomment */}
            {/* <div class="leftAdminCard">
              <Link to="/uRMCandidateDashboard" class="cardMenuAdmin">
                <h3>URM Candidate Dashboard</h3>
              </Link>
            </div> */}
            {/* 6 */}
            {/* uncomment */}
            {/* <div class="leftAdminCard">
              <Link to="/academicInstitutionDashboard" class="cardMenuAdmin">
                <h3>Academic Institution Dashboard</h3>
              </Link>
            </div> */}
          </div>

          <div class="leftLeftAdmin">
            {/* 7 */}
            <div class="leftAdminCard">
              <Link to="/monitorUserActivity" class="cardMenuAdmin">
                <h3>Monitor User Activity</h3>
              </Link>
            </div>
            {/* 8 */}
            <div class="leftAdminCard">
              <Link to="/troubleshoot" class="cardMenuAdmin">
                <h3>Troubleshoot Issues</h3>
              </Link>
            </div>
          </div>
          <div class="leftLeftAdmin">
            <div class="leftAdminCard">
              <Link to="/chat" class="cardMenuAdmin">
                <h3>Chat</h3>
              </Link>
            </div>
          </div>
        </div>

        <div class="rightAdmin">
          <div class="rightAdminCard">
            <img src={img_url} class="kpi" alt="Pie Chart" />
            <div class="kpi-info">
              <h4>
                <b>Status of Job Applications</b>
              </h4>
            </div>
          </div>
          <div class="rightAdminCard">
            <h2 class="card-2-kpi">454</h2>
            <div class="kpi-info">
              <h4>
                <b>Number of Job Postings</b>
              </h4>
            </div>
          </div>
          <div class="rightAdminCard">
            <h2 class="card-3-kpi">250</h2>
            <div class="kpi-info">
              <h4>
                <b>URM candidates matching client preferences</b>
              </h4>
            </div>
          </div>
          <div class="rightAdminCard">
            <h2 class="card-3-kpi">120</h2>
            <div class="kpi-info">
              <h4>
                <b>Time spent on portal(sec)</b>
              </h4>
            </div>
          </div>
          <div class="rightAdminCard">
            <h2 class="card-3-kpi">100</h2>
            <div class="kpi-info">
              <h4>
                <b>New candidates Registered</b>
              </h4>
            </div>
          </div>
          <div class="rightAdminCard">
            <h2 class="card-3-kpi">95%</h2>
            <div class="kpi-info">
              <h4>
                <b>Success Rate</b>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
export default Admin;
