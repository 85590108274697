import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
class Preferences extends React.Component {
  render() {
    return (
      <div>
        <Header />

        <div className="container pt-6">
          <div className="row justify-content-center">
            <div className="col-8 col-sm-12 col-md-8 col-lg-8">
              <div className="p-1">
                <div className="candidate-container">
                  <h2>Register Details</h2>
                  <form action="candidate_dashboard.html">
                    <label for="preference1">Position:</label>
                    <input
                      type="text"
                      id="preference1"
                      name="preference1"
                      className="ip"
                    />
                    <br />
                    <br />
                    <label for="preference2">Location:</label>
                    <input
                      type="text"
                      id="preference"
                      name="preference"
                      className="ip"
                    />
                    <br />
                    <br />
                  </form>
                  <br />
                  <br />
                  <input type="submit" value="Submit" />
                  <input type="button" value="Back" onclick="history.back()" />
                </div>
              </div>
            </div>
            <div className="col-2 col-sm-12 col-md-4 col-lg-4">
              <div className="p-1">
                <div className="academia-jobs">
                  <h2>Quicklinks</h2>
                  <ul className="">
                    <li>
                      <Link to="/DashBoard">Dashboard</Link>
                    </li>
                    <li>
                      <Link to="/ManageProfile">Manage Profile</Link>
                    </li>
                    <li>
                      <Link to="/ApplyJobs">Apply for jobs</Link>
                    </li>
                    <li>
                      <Link to="/Chats">Chats</Link>
                    </li>
                    <li>
                      <Link to="/AppliedJobs">Applied jobs</Link>
                    </li>
                    <li>
                      <Link to="/Preferences">Preferences</Link>
                    </li>
                    <li>
                      <Link to="/home">SignOut</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default Preferences;
