import React from"react";
import {Link} from "react-router-dom";

class Header extends React.Component{
    render(){
        return( 
            <header>
        <div className="navbar">
            <div className="container">
                <ul className="nav-list">
                <li class="company-item p-2">
                    <Link to="/" class="nav-item-links"><p class="company-title">URM website</p></Link>
                    </li>
                    <li className="nav-item p-2">
                        <Link to="/" className="nav-item-links">Home</Link>
                    </li>
                    <li class="nav-item p-2">
                        <Link to="/Contact_Us" class="nav-item-links">
                            Contact Us
                        </Link>
                    </li>
                    <li class="nav-item p-2">
                        <Link to={{ pathname: "../blog/" }} target="_blank" class="nav-item-links">
                        Blog
                        </Link>
                    </li>
                    <li className="nav-item p-2">
                    <Link to="/logout" className="nav-item-links">Logout</Link>
                    </li>

                </ul>
            </div>
        </div>
    </header> 
        );
    }
}
export default Header;
