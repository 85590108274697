import React from "react";
import { Link } from "react-router-dom";
class Footer extends React.Component {
  render() {
    return (
      <footer className="pt-1 b-0">
        <div className="ft-container text-center">
          <div className="row">
            <div className="col-4 col-lg-4 col-md-4 col-sm-4">
              <ul className="nav-list">
                <li>
                  <h2>Website Links</h2>
                </li>
                <li className="pt-1">
                  <Link to="/home">Home</Link>
                </li>
                <li className="pt-1">
                  <Link to="/about">About</Link>
                </li>
                <li className="pt-1">
                  <Link to="/services">Services</Link>
                </li>
                <li className="pt-1">
                  <Link to="/ContactUs">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="col-4 col-lg-4 col-md-4 col-sm-4">
              <ul className="nav-list">
              <li>
                <h2>Other Profiles</h2>
              </li>
              <li>
                <Link to="/URMCandidateDashboard">Candidate</Link>
              </li>
              <li class="pt-1">
                <Link to="/dashboard">Dei</Link>
              </li>
              <li class="pt-1">
                <Link to="/Academia_Dashboard">Academia</Link>
              </li>
              <li class="pt-1">
                <Link to="/Admin">Admin</Link>
              </li>
              </ul>
            </div>
            <div className="col-4 col-lg-4 col-md-4 col-sm-4">
              <ul className="nav-list">
                <li>
                  <h2>Social</h2>
                </li>
                <li>Facebook</li>
                <li className="pt-1">Instagram</li>
                <li className="pt-1">Twitter</li>
                <li className="pt-1">Threads</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
