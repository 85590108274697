import React, { Fragment } from "react";
import Header from "./Header";
import PageName from "./PageName";
import "./admin.css";
import "./admin2.css";
import Footer from "./Footer";
function Chat() {
  return (
    <Fragment>
      <Header />
      <h2>
        <PageName pageName="Chat" />
      </h2>

      <div class="container-chat">
        <div class="child-container">
          <div class="sender">
            <p>Hello! How are you?</p>
            <span class="time-right">11:00</span>
          </div>

          <div class="receiver">
            <p>Hey! I am good. Thanks for asking</p>
            <span class="time-left">11:01</span>
          </div>

          <div class="sender">
            <p>How may I help you?</p>
            <span class="time-right">11:00</span>
          </div>

          <div class="receiver">
            <p>
              I am using your URM portal and I must say it is easy to use and
              helped me in clearing an interview from a tech giant.
            </p>
            <span class="time-left">11:01</span>
          </div>

          <br />
          <div class="text-box">
            <textarea
              placeholder="Type your message.."
              class="input-msg-chat"
            ></textarea>
          </div>
          <button type="submit" class="button-submit">
            Send
          </button>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
}
export default Chat;
