import Header from "./Header";
import Footer from "./Footer";
import React, { Fragment } from "react";

import "./style.css";

function About() {
  return (
    <Fragment>
      <Header />
      <div class="container pt-6 text-center">
        <h1 class="text-color-1">About Us</h1>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-7">
            <div class="academia-jobs">
              <p>
                URM application is aimed to serve as a platform to connect
                academic institutions, URM (underrepresented minority)
                candidates, and DEI (diversity, equity, and inclusion) officers.
              </p>
              <p>
                We assist the academic institutions by providing them a list of
                potential URM candidates for PhD studies, postdoc positions and
                faculty positions, while also guiding the URM candidates by
                offering them a list of academic institutions for their postdoc
                or faculty applications.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
export default About;
