import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "../Academia/style.css";

function Header() {
  return (
    <Fragment>
      {/* <header>
      <div class="navbar">
        <div class="container">
          <ul class="nav-list ">
            <li class="company-item p-2">
              <Link to="/" class="nav-item-links">
                <p class="company-title">URM website</p>
              </Link>
            </li>
            <li class="nav-item p-2">
              <Link to="/home" class="nav-item-links">
                Home
              </Link>
            </li>
            <li class="nav-item p-2">
              <Link to="/" class="nav-item-links">
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header> */}
    <header>
      <div class="navbar">
        <div class="container">
          <ul class="nav-list ">
          <li class="company-item p-2">
                    <Link to="/" class="nav-item-links"><p class="company-title">URM website</p></Link>
                    </li>
                    <li className="nav-item p-2">
                        <Link to="/" className="nav-item-links">Home</Link>
                    </li>
                    <li class="nav-item p-2">
                        <Link to="/Contact_Us" class="nav-item-links">
                            Contact Us
                        </Link>
                    </li>
                    <li class="nav-item p-2">
                        <Link to={{ pathname: "../blog/" }} target="_blank" class="nav-item-links">
                        Blog
                        </Link>
                    </li>
                    <li className="nav-item p-2">
                    <Link to="/logout" className="nav-item-links">Logout</Link>
                    </li>
          </ul>
        </div>
      </div>
    </header>
      {/* <nav class="navbar">
        <div class="company-name">URM.</div>
        <ul>
          <li>
            <Link to="/home">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav> */}
    </Fragment>
  );
}
export default Header;
