import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
class DashBoardURM extends React.Component {
  render() {
    return (
      <Fragment>
        <div>
          <Header />
          <div className="container pt-4">
            <div className="row justify-content-center">
              <div className="col-7 col-sm-12 col-md-12 col-lg-12">
                <div className="p-1">
                  <div className="academia-dashboard">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                        <h3 className="font-italic">Dashboard</h3>
                      </div>
                      <div className="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                        <div className="p-2">
                          <div className="academia-card">
                            <h3>
                              <Link to="/applyJobs" className="link1">
                                <i
                                  class="fa fa-size fa-solid fa-briefcase"
                                  aria-hidden="true"
                                ></i>
                                Apply for Jobs
                              </Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                        <div className="p-2">
                          <div className="academia-card">
                            <h3>
                              <Link to="/manageProfile" className="link1">
                                ManageProfile
                              </Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                        <div className="p-2">
                          <div className="academia-card">
                            <h3>
                              <Link to="/chat" className="link1">
                                Chats
                              </Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                        <div className="p-2">
                          <div className="academia-card">
                            <h3>
                              <Link to="/appliedJobs" className="link1">
                                AppliedJobs
                              </Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                        <div className="p-2">
                          <div className="academia-card">
                            <a>
                              <h3>
                                <Link to="/preferences" className="link1">
                                  Preferences
                                </Link>
                              </h3>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                        <div className="p-2">
                          <div className="academia-card">
                            <a href="#" className="href text-no-decoration ">
                              <h3>
                                <Link to="/home" className="link1">
                                  SignOut
                                </Link>
                              </h3>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </Fragment>
    );
  }
}

export default DashBoardURM;
