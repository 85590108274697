import React, { Fragment } from "react";
import "./style.css"; // Make sure to import the CSS file if it exists.
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

function DeiCreateevents() {
  return (
    <Fragment>
      <Header />
      <div>
        <div className="container pt-6">
          <div className="row justify-content-center">
            <div className="col-6 col-sm-12 col-md-8 col-lg-8">
              <div className="p-1">
                <div className="academia-jobs">
                  <h2>Create Event</h2>
                  <form action="/" method="post">
                    <input
                      type="text"
                      className="ip"
                      placeholder="Enter Event Name"
                    />
                    <br />
                    <br />
                    <input
                      type="text"
                      className="ip"
                      placeholder="Enter Event Description"
                    />
                    <br />
                    <br />
                    <input
                      type="text"
                      className="ip"
                      placeholder="Enter Event Location"
                    />
                    <br />
                    <br />
                    <input
                      type="text"
                      className="ip"
                      placeholder="Enter Event Date"
                    />
                    <br />
                    <br />
                    <br />
                    <input
                      type="submit"
                      className="ip-button"
                      value="Publish"
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="col-2 col-sm-12 col-md-4 col-lg-4">
              <div className="p-1">
                <div className="academia-jobs">
                  <h2>Quicklinks</h2>
                  <ul className="">
                    <li>
                      <Link to="/Dashboard" className="href">
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link to="/DeiEvents" className="href">
                        Event List
                      </Link>
                    </li>
                    <li>
                      <Link to="/DeiCreateevents" className="href">
                        Create Event
                      </Link>
                    </li>
                    <li>
                      <Link to="/DeiApplications" className="href">
                        View Applications
                      </Link>
                    </li>
                    <li>
                      <Link to="/academia_chats" className="href">
                        Chats
                      </Link>
                    </li>
                    <li>
                      <Link to="/academia_chats" className="href">
                        Settings
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default DeiCreateevents;
