import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PageName from "./PageName";
import "./admin.css";
import "./admin2.css";

function MonitoUserActivity() {
  return (
    <Fragment>
      <Header />
      <h2>
        <PageName pageName="Monitor User Activity" />
      </h2>

      <div class="container-monitor">
        <div class="card-monitor">
          <table>
            <tr class="data-table">
              <th class="heading-table">Timezone</th>
              <th>Central Daylight Timezone</th>
            </tr>
            <tr class="data-table">
              <th class="heading-table">Country</th>
              <th>USA</th>
            </tr>
            <tr class="data-table">
              <th class="heading-table">Time spent on the portal(sec)</th>
              <th>120</th>
            </tr>
            <tr class="data-table">
              <th class="heading-table">Number of Active users</th>
              <th>200</th>
            </tr>
            <tr class="data-table">
              <th class="heading-table">Number of daily logins</th>
              <th>150</th>
            </tr>
            <tr class="data-table">
              <th class="heading-table">
                Number of new Job postings in a week
              </th>
              <th>90</th>
            </tr>
            <tr class="data-table">
              <th class="heading-table">Number of Jobs applied</th>
              <th>200</th>
            </tr>
          </table>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
export default MonitoUserActivity;
