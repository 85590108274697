import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import React, { Fragment } from "react";

import "./style.css";

function Sign() {
  return (
    <Fragment>
      <Header />
      <div class="container pt-4 text-center">
        <h1 class="text-color-1 font-italic">Join Our Community</h1>
      </div>
      <div class="container pt-2 pb-4">
        <div class="row">
          <div class="col-2"></div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-4">
            <div class="p-1">
              <div class="card">
                <h3 class="head-color">Login</h3>
                <form action="/Sign" class="p-0">
                  <input type="text" class="ip" placeholder="Email Address" />
                  <br />
                  <br />
                  <input
                    type="password"
                    name=""
                    class="ip"
                    placeholder="Password"
                  />
                  <br />
                  <br />
                  <Link to="/forgotPassword">Forgot Password?</Link>
                  {/* <a href="forgot_password.html" class="href">Forgot Password?</a> */}
                  <br />
                  <br />
                  <br />
                  <button type="submit" class="ip-button">
                    Sign In
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-4">
            <div class="p-1">
              <div class="card">
                <h3 class="head-color">Register</h3>
                <form action="/Sign">
                  <input type="text" class="ip" placeholder="First Name" />
                  <br />
                  <br />
                  <input
                    type="text"
                    class="ip ip-style"
                    placeholder="Last Name"
                  />
                  <br />
                  <br />
                  <input
                    type="text"
                    name=""
                    class="ip"
                    placeholder="Email Address"
                  />
                  <br />
                  <br />
                  <br />
                  <button type="submit" class="ip-button">
                    Register
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div class="col-2"></div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Sign;
