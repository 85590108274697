import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import React, { Fragment } from "react";

import "./style.css";
function Academia_Dashboard() {
  return (
    <Fragment>
      <Header />

      <div class="container pt-4">
        <div class="row justify-content-center">
          <div class="col-7 col-sm-12 col-md-7 col-lg-7">
            <div class="p-1">
              <div class="academia-dashboard">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                    <h3 class="">Dashboard</h3>
                  </div>
                  <div class="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                    <div class="p-2">
                      <div class="academia-card">
                        <Link to="/academia_jobs" class="text-no-decoration">
                          <i
                            class="fa fa-size fa-solid fa-briefcase"
                            aria-hidden="true"
                          ></i>
                          <h3>Job Listings</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                    <div class="p-2">
                      <div class="academia-card">
                        <Link
                          to="/academia_candidate"
                          class="href text-no-decoration"
                        >
                          <i class="fa fa-size fa-duotone fa-user"></i>
                          <h3>Candidates Profiles</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                    <div class="p-2">
                      <div class="academia-card">
                        <Link to="/chat" class="href text-no-decoration">
                          <i class="a fa-size fa-solid fa-message"></i>
                          <h3>Chat</h3>
                        </Link>
                        {/* <a
                          href="admin/Chat.html"
                          class="href text-no-decoration"
                        >
                          <i
                            class="fa fa-size fa-solid fa-message"
                            aria-hidden="true"
                          ></i>
                          <h3>Chats</h3>
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                    <div class="p-2">
                      <div class="academia-card">
                        <Link
                          to="/academia_application"
                          class="href text-no-decoration"
                        >
                          <i class="fa fa-size fa-solid fa-file"></i>
                          <h3>View Applications</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                    <div class="p-2">
                      <div class="academia-card">
                        <Link
                          to="/academia_create_job"
                          class="href text-no-decoration"
                        >
                          <i
                            class="fa fa-size fa-regular fa-pen-to-square"
                            aria-hidden="true"
                          ></i>
                          <h3>Create Jobs</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-sm-12 col-md-6 col-lg-4 text-center">
                    <div class="p-2">
                      <div class="academia-card">
                        <Link
                          to="/academia_about"
                          class="href text-no-decoration">
                          <i class="fa fa-size fa-solid fa-circle-info"></i>
                          <h3>About</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3 col-sm-12 col-md-4 col-lg-4">
            <div class="p-1">
              <div class="academia-dashboard">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                    <h3 class="">Statistics</h3>
                  </div>
                  <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                    <div class="p-1">
                      <div class="academia-stats">
                        <h2>200+</h2>
                        <p>Jobs Posted</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                    <div class="p-1">
                      <div class="academia-stats">
                        <h2>500+</h2>
                        <p>Applications</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                    <div class="p-1">
                      <div class="academia-stats">
                        <h2>20+</h2>
                        <p>Interested Students</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                    <div class="p-1">
                      <div class="academia-stats">
                        <h2>100+</h2>
                        <p>Another Stat</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
export default Academia_Dashboard;
