import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
class ManageProfile extends React.Component {
  render() {
    return (
      <div>
        <Header />

        <div className="container pt-6">
          <div className="row justify-content-center">
            <div className="col-8 col-sm-12 col-md-8 col-lg-8">
              <div className="p-1">
                <div className="candidate-container">
                  <h2>Register Details</h2>
                  <form action="/action_page.php">
                    <label for="fname">First name:</label>
                    <input type="text" id="fname" name="fname" className="ip" />
                    <br />
                    <br />
                    <label for="lname">Last name:</label>
                    <input type="text" id="lname" name="lname" className="ip" />
                    <br />
                    <br />
                    <label for="mail">E-Mail:</label>
                    <input type="mail" id="mail" name="mail" className="ip" />
                    <br />
                    <br />
                    <label for="phoneno">Mobile No:</label>
                    <input
                      type="number"
                      id="phoneno"
                      name="phoneno"
                      className="ip"
                    />
                    <br />
                    <br />
                    <label for="education">Education:</label>
                    <input
                      type="text"
                      id="education"
                      name="education"
                      className="ip"
                    />
                    <br />
                    <br />
                    <label for="studyarea">StudyArea:</label>
                    <input
                      type="text"
                      id="studyarea"
                      name="studyarea"
                      className="ip"
                    />
                    <br />
                    <br />
                    <label for="resume">Resume</label>
                    <input type="file" name="resume" id="" className="ip" />
                    <br />
                    <br />
                    <label for="academic_records">Academic Records</label>
                    <input
                      type="file"
                      name="academic_records"
                      id=""
                      className="ip"
                    />
                    <br />
                    <br />
                    <label for="cv">Cover Letter</label>
                    <input type="file" name="cv" id="" class="ip" />
                    <br />
                    <br />
                  </form>
                  <br />
                  <br />
                  <input type="submit" value="Submit" class="ip-button" />
                </div>
              </div>
            </div>
            <div className="col-2 col-sm-12 col-md-4 col-lg-4">
              <div className="p-1">
                <div className="academia-jobs">
                  <h2>Quicklinks</h2>
                  <ul className="">
                    <li>
                      <Link to="/DashBoard">Dashboard</Link>
                    </li>
                    <li>
                      <Link to="/ManageProfile">Manage Profile</Link>
                    </li>
                    <li>
                      <Link to="/ApplyJobs">Apply for jobs</Link>
                    </li>
                    <li>
                      <Link to="/Chats">Chats</Link>
                    </li>
                    <li>
                      <Link to="/AppliedJobs">Applied jobs</Link>
                    </li>
                    <li>
                      <Link to="/Preferences">Preferences</Link>
                    </li>
                    <li>
                      <Link to="/home">SignOut</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default ManageProfile;
