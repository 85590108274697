import career_img from "../img/career.jpg";
import career_img2 from "../img/career_2.jpg";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import React, { Fragment } from "react";

import "./style.css";
function Home() {
  return (
    <Fragment>
      <Header />
      <div class="container text-center">
        <h2 class="text-color-1">Home</h2>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-7 col-sm-12 col-md-7 col-lg-7">
            <div class="p-1">
              <h4>
                <p>
                  A platform to connect academic institutions, URM
                  (underrepresented minority) candidates, and DEI (diversity,
                  equity, and inclusion) officers.
                </p>
                <p>
                  This is a platform to connect academic institutions, URM
                  (underrepresented minority) candidates, and DEI (diversity,
                  equity, and inclusion) officers.
                </p>
              </h4>
              <br />
              <Link to="/Sign" class="href ip-button text-no-decoration">
                Join Now!
              </Link>
            </div>
          </div>
          <div class="col-4 col-sm-12 col-md-4 col-lg-4">
            <div class="p-1">
              <img src={career_img} className="w-100" />
            </div>
          </div>
        </div>

        <br />

        <br />
        <br />
        <br />
        <div class="row justify-content-center">
          <div class="col-4 col-sm-12 col-md-4 col-lg-4">
            <div class="p-1">
              <img src={career_img2} className="w-100" />
            </div>
          </div>
          <div class="col-7 col-sm-12 col-md-7 col-lg-7">
            <div class="p-1">
              <div class="line">
                <h2>Find the Right fit!</h2>
                <br />
                <hr class="line-hr-home" />
                <br />
                <p>
                  Transform your career. Begin your journey by searching our
                  latest career opportunities!
                </p>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
export default Home;
