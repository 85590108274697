import Header from "./Header";
import Footer from "./Footer";
import React, { Fragment } from "react";

import "./style.css";
import Quicklinks from "./Quicklinks";

function Academia_Application() {
  return (
    <Fragment>
      <Header />
      <div class="container pt-6">
        <div class="row justify-content-center">
          <div class="col-8 col-sm-12 col-md-8 col-lg-8">
            <div class="p-1">
              <div class="academia-jobs">
                <div class="">
                  <h2>Received Applications</h2>
                </div>
                <div class="job-card pt-4">
                  <h3>Joshua Menn</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <p>Applied for: Ph D</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-accept p-1 text-no-decoration"
                        >
                          Accept
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-reject p-1 text-no-decoration"
                        >
                          Reject
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h3>Joshua Menn</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <p>Applied for: Ph D</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-accept p-1 text-no-decoration"
                        >
                          Accept
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-reject p-1 text-no-decoration"
                        >
                          Reject
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h3>Joshua Menn</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <p>Applied for: Ph D</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-accept p-1 text-no-decoration"
                        >
                          Accept
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-reject p-1 text-no-decoration"
                        >
                          Reject
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h3>Joshua Menn</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <p>Applied for: Ph D</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-accept p-1 text-no-decoration"
                        >
                          Accept
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-reject p-1 text-no-decoration"
                        >
                          Reject
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h3>Joshua Menn</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <p>Applied for: Ph D</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-accept p-1 text-no-decoration"
                        >
                          Accept
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-reject p-1 text-no-decoration"
                        >
                          Reject
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                  <hr class="text-color-1" />
                </div>
                <div class="job-card pt-4">
                  <h3>Joshua Menn</h3>
                  <p>Hagerty Consultancy</p>
                  <p>United States</p>
                  <p>Applied for: Ph D</p>
                  <div class="text-right">
                    <ul class="academic-job-list">
                      <li>
                        <a
                          href="#"
                          class="href job-accept p-1 text-no-decoration"
                        >
                          Accept
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          class="href job-reject p-1 text-no-decoration"
                        >
                          Reject
                        </a>
                      </li>
                    </ul>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Quicklinks />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Academia_Application;
