import Header from "./Header";
import Footer from "./Footer";
import React, { Fragment } from "react";

import "./style.css";

function Forgot_Password() {
  return (
    <Fragment>
      <Header />

      <div class="container pt-6">
        <div class="row justify-content-center">
          <div class="col-4 col-sm-12 col-md-12 col-lg-4">
            <div class="card">
              <h2>Recover Account</h2>
              <form action="/Sign">
                <input
                  type="email"
                  class="ip"
                  name="email"
                  placeholder="Enter Your Email"
                />
                <br />
                <br />
                <input
                  type="button"
                  class="ip-button"
                  value="Send Reset Link"
                />
              </form>
            </div>
          </div>
        </div>

        <div class="pt-6"></div>
        <div class="pt-6"></div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Forgot_Password;
