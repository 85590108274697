import React, { Fragment } from "react";
import "./style.css"; // Make sure to import the CSS file if it exists.
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

function DeiGoals() {
  return (
    <Fragment>
      <div>
        <Header />
        <div className="container pt-6">
          <div className="row justify-content-center">
            <div className="col-8 col-sm-12 col-md-12 col-lg-12">
              <div className="p-1">
                <div className="academia-jobs">
                  <div>
                    <h2>DEI Goals</h2>
                  </div>

                  <div className="job-card pt-4">
                    <div>
                      <h3>Goal 1: Increase Diversity</h3>
                      <p>
                        We aim to increase diversity among faculty and staff to
                        create a more inclusive and representative academic
                        community. This involves implementing inclusive hiring
                        practices and actively recruiting underrepresented
                        minority candidates.
                      </p>
                    </div>
                    <hr className="text-color-1" />
                  </div>

                  <div className="job-card pt-4">
                    <div>
                      <h3>Goal 2: Equity in Education</h3>
                      <p>
                        Our initiative focuses on ensuring equitable access to
                        education and resources for all students. We provide
                        support programs and scholarships for underprivileged
                        students to help them succeed academically and
                        professionally.
                      </p>
                    </div>
                    <hr className="text-color-1" />
                  </div>

                  <div className="job-card pt-4">
                    <div>
                      <h3>Goal 3: Inclusive Curriculum</h3>
                      <p>
                        We are committed to developing an inclusive curriculum
                        that reflects diverse perspectives and cultures. Our
                        goal is to provide students with a well-rounded
                        education that prepares them for a diverse and
                        globalized world.
                      </p>
                    </div>
                    <hr className="text-color-1" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="p-1">
                <div className="academia-jobs">
                  <h2>Quicklinks</h2>
                  <ul className="">
                    <li>
                      <Link to="/Dashboard" className="href">
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link to="/DeiEvents" className="href">
                        Event List
                      </Link>
                    </li>
                    <li>
                      <Link to="/DeiCreateevents" className="href">
                        Create Event
                      </Link>
                    </li>
                    <li>
                      <Link to="/DeiApplications" className="href">
                        View Applications
                      </Link>
                    </li>
                    <li>
                      <Link to="/academia_chats" className="href">
                        Chats
                      </Link>
                    </li>
                    <li>
                      <Link to="/academia_chats" className="href">
                        Settings
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default DeiGoals;
