import Quicklinks from "./Quicklinks";
import Header from "./Header";
import Footer from "./Footer";
import React, { Fragment } from "react";

import "./style.css";

function Academia_Create_Job() {
  return (
    <Fragment>
      <Header />
      <div class="container pt-6">
        <div class="row justify-content-center">
          <div class="col-6 col-sm-12 col-md-8 col-lg-8">
            <div class="p-1">
              <div class="academia-jobs">
                <h2>Create Job</h2>
                <form action="/" method="post">
                  <input type="text" class="ip" placeholder="Enter Job Name" />
                  <br />
                  <br />
                  <input
                    type="text"
                    class="ip"
                    placeholder="Enter Job Description"
                  />
                  <br />
                  <br />
                  <input
                    type="text"
                    class="ip"
                    placeholder="Enter Job Location"
                  />
                  <br />
                  <br />
                  <select id="" class="ip" name="job_type">
                    <option value="PhD">PhD</option>
                    <option value="postdoc">postdoc</option>
                    <option value="faculty">faculty</option>
                  </select>{" "}
                  <br />
                  <br />
                  <br />
                  <input type="submit" class="ip-button" value="Publish" />
                </form>
              </div>
            </div>
          </div>
          <Quicklinks />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Academia_Create_Job;
