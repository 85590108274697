import { Link } from "react-router-dom";
import React, { Fragment } from "react";

import "./style.css";
function Header() {
  return (
    <header>
      <div class="navbar">
        <div class="container">
          <ul class="nav-list ">
          <li class="company-item p-2">
                    <Link to="/" class="nav-item-links"><p class="company-title">URM website</p></Link>
                    </li>
                    <li className="nav-item p-2">
                        <Link to="/" className="nav-item-links">Home</Link>
                    </li>
                    <li class="nav-item p-2">
                        <Link to="/Contact_Us" class="nav-item-links">
                            Contact Us
                        </Link>
                    </li>
                    <li class="nav-item p-2">
                        <Link to={{ pathname: "../blog/" }} target="_blank" class="nav-item-links">
                        Blog
                        </Link>
                    </li>
                    <li className="nav-item p-2">
                    <Link to="/logout" className="nav-item-links">Logout</Link>
                    </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
export default Header;
