import Header from "./Header";
import Footer from "./Footer";
import React, { Fragment } from "react";

import "./style.css";

function Contact_Us() {
  return (
    <Fragment>
      <Header />
      <div class="container pt-4 text-center">
        <h1 class="text-color-1">Connect with Us!</h1>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-4 col-sm-12 col-md-12 col-lg-4">
            <div class="card">
              <h2>Provide Information Here</h2>
              <form action="#">
                <input
                  type="text"
                  class="ip"
                  name="name"
                  placeholder="Enter Your Name"
                />
                <br />
                <br />
                <input
                  type="email"
                  class="ip"
                  name="email"
                  placeholder="Enter Your Email"
                />
                <br />
                <br />
                <input
                  type="text"
                  class="ip"
                  name="subject"
                  placeholder="Subject"
                />
                <br />
                <br />
                <textarea
                  name="description"
                  class="ip"
                  placeholder="Enter Description"
                  cols="10"
                  rows="5"
                ></textarea>
                <br />
                <br />
                <input
                  type="text"
                  class="ip"
                  name="subject"
                  placeholder="Subject"
                />
                <br />
                <br />
                <br />
                <input type="button" class="ip-button" value="Connect" />
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
export default Contact_Us;
