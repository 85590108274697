import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PageName from "./PageName";
import "./admin.css";
import "./admin2.css";
function CreateUserEntry() {
  return (
    <Fragment>
      <Header />
      <h2>
        <PageName pageName="Create User Entry" />
      </h2>

      <div class="container">
        <div class="card-create">
          <div class="card-form-create">
            <form>
              <div class="form-menu-create">
                <label for="name">Name:</label>
                <input type="text" class="ip" id="name" name="name" required />
              </div>
              <div class="form-menu-create">
                <label for="email">Email:</label>
                <input type="email" class="ip"id="email" name="email" required />
              </div>
              <div class="form-menu-create">
                <label for="name">Contact Number:</label>
                <input type="number" class="ip"id="contact" name="number" required />
              </div>
              <div class="form-menu-create">
                <label for="name">Date of Birth:</label>
                <input type="date" class="ip"id="date" name="date" required />
              </div>
              <div class="form-menu-create">
                <label for="message">Address:</label>
                <textarea
                  id="message"
                  name="message"
                  rows="3"
                  required
                ></textarea>
              </div>
              <div class="form-menu-create">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
export default CreateUserEntry;
