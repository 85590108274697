import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
class ApplyJobs extends React.Component {
  render() {
    return (
      <div>
        <Header />

        <div className="container pt-6">
          <div className="row justify-content-center">
            <div className="col-8 col-sm-12 col-md-8 col-lg-8">
              <div className="p-1">
                <div className="academia-jobs">
                  <div className="">
                    <h2>Jobs Posted</h2>
                  </div>
                  <div className="job-card pt-4">
                    <h3>Research Assistant</h3>
                    <p>Hagerty Consultancy</p>
                    <p>United States</p>
                    <p>
                      <Link
                        to="/AcademiaAboutCandidate"
                        className="href"
                        target="_blank"
                      >
                        Visit Website
                      </Link>
                    </p>
                    <div className="text-right">
                      <ul className="academic-job-list">
                        <li>
                          <a
                            href="#"
                            className="href job-view p-1 text-no-decoration"
                          >
                            <i className="fa fa-solid fa-eye"></i>
                            View
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="href job-update p-1 text-no-decoration"
                          >
                            <i className="fa fa-solid fa-plus"></i>
                            Apply
                          </a>
                        </li>
                      </ul>
                      <br />
                    </div>
                    {/* <hr className="text-color-1"> */}
                  </div>
                  <div className="job-card pt-4">
                    <h3>Research Assistant</h3>
                    <p>Hagerty Consultancy</p>
                    <p>United States</p>
                    <p>
                      <Link
                        to="/AcademiaAboutCandidate"
                        className="href"
                        target="_blank"
                      >
                        Visit Website
                      </Link>
                    </p>
                    <div className="text-right">
                      <ul className="academic-job-list">
                        <li>
                          <a
                            href="#"
                            className="href job-view p-1 text-no-decoration"
                          >
                            <i className="fa fa-solid fa-eye"></i>
                            View
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="href job-update p-1 text-no-decoration"
                          >
                            <i className="fa fa-solid fa-plus"></i>
                            Apply
                          </a>
                        </li>
                      </ul>
                      <br />
                    </div>
                    {/* <hr className="text-color-1"> */}
                  </div>
                  <div className="job-card pt-4">
                    <h3>Research Assistant</h3>
                    <p>Hagerty Consultancy</p>
                    <p>United States</p>
                    <p>
                      <Link
                        to="/AcademiaAboutCandidate"
                        className="href"
                        target="_blank"
                      >
                        Visit Website
                      </Link>
                    </p>
                    <div className="text-right">
                      <ul className="academic-job-list">
                        <li>
                          <a
                            href="#"
                            className="href job-view p-1 text-no-decoration"
                          >
                            <i className="fa fa-solid fa-eye"></i>
                            View
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="href job-update p-1 text-no-decoration"
                          >
                            <i className="fa fa-solid fa-plus"></i>
                            Apply
                          </a>
                        </li>
                      </ul>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2 col-sm-12 col-md-4 col-lg-4">
              <div className="p-1">
                <div className="academia-jobs">
                  <h2>Quicklinks</h2>
                  <ul className="">
                    <li>
                      <Link to="/DashBoard">Dashboard</Link>
                    </li>
                    <li>
                      <Link to="/ManageProfile">Manage Profile</Link>
                    </li>
                    <li>
                      <Link to="/ApplyJobs">Apply for jobs</Link>
                    </li>
                    <li>
                      <Link to="/Chats">Chats</Link>
                    </li>
                    <li>
                      <Link to="/AppliedJobs">Applied jobs</Link>
                    </li>
                    <li>
                      <Link to="/Preferences">Preferences</Link>
                    </li>
                    <li>
                      <Link to="/Home">SignOut</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default ApplyJobs;
